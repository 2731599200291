<template>
  <div v-if="!basketIs.initial">
    <strong>{{ location.name }}</strong>, {{ locationAddress.street }}, {{ locationAddress.zip }} {{ locationAddress.city }}
  </div>
</template>

<script>
export default {
  name: 'CheckoutLocationAddress',
  props: {
    addressId: {
      type: String,
      default: null
    },
    predefined: {
      type: Object,
      required: true,
      default: () => ({
        location: null,
        address: null
      })
    }
  },
  computed: {
    basketIs () {
      return this.$store.getters['shoppingcart/is']
    },
    basketConfiguration () {
      return this.$store.getters['shoppingcart/getConfiguration']
    },
    location () {
      return this.predefined.location
    },
    locationAddress () {
      return this.predefined.address
    }
  },
  created () {
  }
}
</script>

<style lang="scss"></style>
